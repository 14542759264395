import styled from "styled-components"

export const Styles = styled.div`

border-radius: 10px;
overflow: hidden;
background: linear-gradient(99.36deg, #25897B 6.16%, #3BD5BE 93.31%);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.cal_header {
  display: flex;
  flex-direction: row;
  padding: 24px;

  button {
    background: none;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
  }

  h3 {
    padding-top: 3px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.cal_body {
  overflow-x: auto;
  padding: 2px;

  .cal_body_container {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: repeat(7, minmax(45px, 1fr));
    grid-template-rows: 45px repeat(5, 61px);

    > div {
      color: black;
      background: white;
    }

    .cal_body_head {
      display: flex;
      justify-content: center;
      align-items: center;

      h3 {
        color: #01081F;
        font-weight: 500;
      }
    }

    .cal_body_date {
      display: flex;
      padding: 8px 10px 6px 10px;
      cursor: pointer;
      position: relative;
      flex-direction: column;

      &.active .cal_date:before {
        content: " ";
        position: absolute;
        height: 17px;
        width: 17px;
        border-radius: 25px;
        background: #279686;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &.active .cal_date {
        color: white;
        position: relative;
        z-index: 0;
        align-self: flex-start;
      }

      &.inactive .cal_date {
        opacity: 30%;
      }

      .cal_date {
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 2px;
      }

      .cal_more {
        flex: 1;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        a {
          color: #279686;
          font-size: 7px;
          text-decoration: underline;
          position: absolute;
        }
      }

      ul {
        list-style-position: inside; 
        list-style: none;
        font-size: 8px;
        margin-left: -3px;
        font-family: "DM Sans", sans-serif;

        li {
          position: relative;
          display: flex;
          align-items: center;
        }

        li span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        li:before {
          content: " ";
          width: 4px;
          height: 4px;
          margin-right: 10%;
          border-radius: 50%;
          flex-shrink: 0;
          background: #279686;
        }
      } // ul
    } // cal_body_date
  } // cal_body_container
} // cal_body

@media screen and (min-width: 769px) {
  .cal_body .cal_body_container {
    grid-template-rows: 50px repeat(5, 100px);
  }

  .cal_body .cal_body_container .cal_body_date {
    padding: 15px 15px 8px 15px;

    .cal_date {
      font-size: 14px;
    }

    ul {
      font-size: 10px
    }

    .cal_more a {
      font-size: 10px;
      position: initial;
    }
  }
}

@media screen and (min-width: 992px) {
  .cal_body .cal_body_container {
    grid-template-rows: 50px repeat(5, 130px);
  }

  .cal_body_head h3 {
    font-size: 16px;
  }

  .cal_body .cal_body_container .cal_body_date {

    &.active .cal_date:before {
      height: 22px;
      width: 22px;
    }

    .cal_date {
      font-size: 16px;
      margin-bottom: 8px;
    }

    ul {
      font-size: 12px;
      margin-left: 3px;
    }

    ul li {
      margin-bottom: 8px;

      &:before {
        width: 6px;
        height: 6px;
      }
    }

    .cal_more a {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 1250px) {
  .cal_body .cal_body_container {
    grid-template-rows: 80px repeat(5, 160px);
  }

  .cal_body .cal_body_container .cal_body_date {
    padding: 20px 20px 16px 20px;

    &.active .cal_date:before {
      height: 25px;
      width: 25px;
    }

    .cal_date {
      font-size: 18px;
      margin-bottom: 16px;
    }

    ul {
      font-size: 14px;
      margin-left: 2px;
    }

    ul li {
      margin-bottom: 16px;

      &:before {
        width: 10px;
        height: 10px;
      }
    }

    .cal_more a {
      font-size: 14px;
    }
  }

  .cal_header {
    padding: 32px 37px;
  }

  .cal_body_head h3 {
    font-size: 18px;
  }
}

@media screen and (min-width: 1440px) {
  max-width: 1212px;

  .cal_body .cal_body_container {
    grid-template-rows: 73px repeat(5, 171px);

    .cal_body_date {
      padding: 25px 25px 16px 25px;
    }

    .cal_header h3 {
      font-size: 24px;
    }
  }
}
`

export const PopupStyles = styled.div`
  background: rgba(0, 0, 0, 0.6); 
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .popupContainer {
    color: black;
    background: white;
    opacity: 1;
    border-radius: 10px;
    height: 419px;
    width: 90vw;
    max-width: 1008px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;

    ul {
      align-self: flex-start;
      overflow-y: auto;
      list-style: none;
      align-self: stretch;
      padding: 0 24px;

      li {
        display: flex;
        flex-direction: row;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        &:before {
          content: " ";
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: #279686;
          margin-right: 12px;
          margin-top: 2px;
          flex-shrink: 0;
        }

        .popup_item {
          .popup_item_header {
            display: flex;
            margin: 0;
            margin-bottom: 12px;
            font-weight: 500;
            font-size: 16px;
          }

          .popup_item_desc {
            font-size: 14px;
          }
        }
      }
    }

    button {
      align-self: flex-end;
      background: none;
      border: none;
      padding-right: 24px;
    }

    .popup_header {
      font-size: 18px;
    }
  }

@media screen and (min-width: 992px) {
  .popupContainer {

    .popup_header {
      font-size: 24px;
    }

    ul {
      padding: 0 36px;

      li {
        &:before {
          margin-right: 20px;
        }

        .popup_item {
          .popup_item_header {
            font-size: 18px;
          }

          .popup_item_desc {
            font-size: 14px;
          }
        }
      }
      }

    button {
      padding-right: 20px;
    }
  }
}

`
