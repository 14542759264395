import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DefaultDashboardPage from "../../components/DashboardPage/index-dashboard"
import DashboardCalendar from "../../components/DashboardPage/DashboardCalendar"

const CalendarPage = () => (
  <Layout>
    <SEO title="Calendar" />
    <DefaultDashboardPage>
      <DashboardCalendar />
    </DefaultDashboardPage>
  </Layout>
)

export default CalendarPage
