import React, {useEffect, useState} from "react"

import { Styles, PopupStyles } from "./style"
import left_chev from "../../../../assets/images/dashboard-assets/dash_cal_chev_left.svg"
import right_chev from "../../../../assets/images/dashboard-assets/dash_cal_chev_right.svg"

import {useSelector, useDispatch} from "react-redux";
import {loadEvents} from "../../../redux/slices/event/thunks";
import {eventsSelector} from "../../../redux/slices/recruitment/selectors";

import useGetWindowWidth from '../../../utils/useGetWindowWidth';

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "Septermber", "October", "November", "December"]

// helper funcs

// zeroth index
const daysInAMonth = (month, year) => new Date(year, month+1, 0).getDate()

const groupEventsByMonth = (eventsArray) => {
   return eventsArray.reduce((acc, obj) => {
      const key = new Date(obj["start_at"]).getMonth()
      if (!acc[key]) {
         acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
   }, {});
}

const groupEventsByDate = (eventsArray) => {
   return eventsArray.reduce((acc, obj) => {
      const key = new Date(obj["start_at"]).getDate()
      if (!acc[key]) {
         acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
   }, {});
}

// popup
const CalendarPopup = ({ togglePopup, selectedEvents }) => {
  const selectedDate = new Date(selectedEvents[0].start_at)
  return (
    <PopupStyles>
        <div className="popupContainer">
            <button className="popup_toggle" onClick={() => togglePopup(false)}>
                <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="25.753" height="2.586" rx="1.293" transform="matrix(.72077 .69317 .55612 -.8311 .001 2.149)" fill="#000"/><rect width="25.753" height="2.586" rx="1.293" transform="matrix(-.72077 .69317 -.55612 -.8311 20 2.149)" fill="#000"/></svg>
            </button>
            <h2 className="popup_header">{ days[(selectedDate.getDay())%7] }, {months[selectedDate.getMonth()]} {selectedDate.getFullYear()}</h2>
            <ul>
                {selectedEvents.map(event => (
                    <li key={event.id}>
                        <div className="popup_item">
                            <h3 className="popup_item_header">{event.name}</h3>
                            <p className="popup_item_desc">{event.description}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    </PopupStyles>
  )
}

// cal
const DashboardCalendar = () => {
  const isMobile = useGetWindowWidth() < 992
  const [showPopup, setShowPopup] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState([])

  const dispatcher = useDispatch()
  useEffect(() => {
    dispatcher(loadEvents())
  }, [])

  const date_now = new Date()
  const events = useSelector(eventsSelector)
  let events_now_grouped = []
  events_now_grouped = groupEventsByMonth(Object.values(events)
    .filter(event => (new Date(event.start_at)).getFullYear() === (new Date()).getFullYear())
    .sort((a,b) => (new Date(a.start_at).getTime() - (new Date(b.start_at).getTime()))))


  let monthItems = Object.values(events_now_grouped)
  let monthArray = Object.keys(events_now_grouped).map(i => parseInt(i))
  monthArray.push(date_now.getMonth())
    monthArray = [...new Set(monthArray)] // Added the current month to the array, also make sure there is no duplicate month

  const [monthIndex, setMonthIndex] = useState(0)
  useEffect(() => setMonthIndex(monthArray.indexOf(date_now.getMonth())), [events])

  const eventsByDate = (index) => monthItems[index] ? groupEventsByDate(monthItems[index]) : []

  const amountDaysNow = daysInAMonth(monthArray[monthIndex], date_now.getFullYear())

  const handlePopup = (date, monthIndex) => {
    setSelectedEvent(eventsByDate(monthIndex)[date])
    setShowPopup(true)
  }

  return (
    <>
      {showPopup && selectedEvent && <CalendarPopup togglePopup={setShowPopup} selectedEvents={selectedEvent} /> }
      <Styles>
        <div className="cal_header">
          <button onClick={() => setMonthIndex(prev => prev-1 < 0 ? 0 : prev-1)}>
            <img src={left_chev} alt="left" />
          </button>
          <h3>{`${months[monthArray[monthIndex]]} ${date_now.getFullYear()}`}</h3>
          <button onClick={() => setMonthIndex(prev => prev+1 >= monthArray.length ? monthArray.length-1 : prev+1)}>
            <img src={right_chev} alt="right"/>
          </button>
        </div>

        <div className="cal_body">
          <div className="cal_body_container">
            
            {/* Calendar header */}
            {days.map((day, idx) => ( 
              <div key={idx} className="cal_body_head">
                <h3>{isMobile ? day.substr(0,3) : day}</h3>
              </div> 
              )
            )}

            {/* Calendar body (dates) */}
            {/* Offset calendar until the first day of the month */}
            {[...Array(35).keys()].map(i => typeof(i-=(new Date(2020, monthArray[monthIndex], 1)).getDay()) === "number" && (
              <div 
                key={i}
                className={
                  `cal_body_date
                  ${date_now.getMonth() === monthArray[monthIndex] && i === date_now.getDate()-1 ? "active" : ""}
                  ${i >= amountDaysNow ? "inactive" : ""}`
                }
                onClick={() => {
                    i-1 <= amountDaysNow ? handlePopup(i+1, monthIndex) : handlePopup(i - amountDaysNow + 1, monthIndex+1) 
                }}
              >
                <p className="cal_date">{i >= 0 && ((i) % amountDaysNow) + 1}</p>
                <ul>
                  {/* Iterate events on the current month */}
                  {eventsByDate(monthIndex)[i+1] && (
                    eventsByDate(monthIndex)[i+1].map(j => (
                      <li key={j.id}><span>{j.name}</span></li>
                    )).slice(0, (isMobile ? 1 : 2))
                  )}

                  {/* Iterate events on the next month if exist */}
                  {eventsByDate(monthIndex+1)[(i - amountDaysNow) + 1] && (
                    eventsByDate(monthIndex+1)[(i - amountDaysNow) + 1].map(j => (
                      <li key={j.id}><span>{j.name}</span></li>
                    )).slice(0, (isMobile ? 1 : 2))
                  )}
                </ul>
                {eventsByDate(monthIndex)[i+1] && 
                    (eventsByDate(monthIndex)[i+1].length > 2) && (
                      <p className="cal_more"><a>+{eventsByDate(monthIndex)[i+1].length - (isMobile ? 1 : 2)} More</a></p>
                )}
                {eventsByDate(monthIndex+1)[(i - amountDaysNow) + 1] && 
                    (eventsByDate(monthIndex+1)[(i - amountDaysNow) + 1].length > 2) && (
                    <p className="cal_more"><a>+{eventsByDate(monthIndex+1)[(i - amountDaysNow) + 1].length - 2} More</a></p>
                )}
              </div> 
              )
            )}

          </div>
        </div>
      </Styles>
    </>
  )
}

export default DashboardCalendar
