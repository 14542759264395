import { createSelector } from "reselect"

function values(obj) {
  return Object.keys(obj)
    .map((k) => obj[k])
    .filter((k) => k)
}

export const teamsSelector = (state) => state.event.teams || []
export const eventsSelector = (state) => state.event.events || []
export const applicationsSelector = (state) =>
  values(state.recruitment.applications || {}) || []

export const hasAnyApplicationsSelector = createSelector(
  applicationsSelector,
  (applications) => applications.length > 0
)

export function getApplicationsByTag(state, { tag }) {
  const { teams, events } = state.event
  if (!teams || !events) {
    return []
  }
  return applicationsSelector(state)
    .filter(({ team }) => {
      const teamObj = teams[team]
      if (!teamObj) return false
      const tags = (events[teamObj.registerable_event] || {}).tags || []
      return tags.includes(tag)
    })
    .map(({ team: teamId, priority }) => {
      const team = teams[teamId]
      // Open recruitment event only consists of 1 task and 1 field (file upload).
      const task = values(team.tasks)[0]
      const field = task.fields[0]
      const { data, updated_at } = field.submission || {}
      return {
        event: events[team.registerable_event],
        team: team,
        task,
        field,
        uploadedFile: {
          url: JSON.parse(data || '""'),
          updatedAt: updated_at,
        },
        downloadLink: JSON.parse(field.data).description,
        priority,
      }
    })
    .sort((a1, a2) => a1.priority - a2.priority)
}
